import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import useFetch from "./functions/useFetch";

import Home from "./screens/Home";
import * as authentication from "./functions/authentication";
import useScreenType from "./functions/useScreenType";
import { LanguageProvider } from "./contexts/LanguageContext";
import Product from "./screens/Product";
import Cart from "./screens/Cart";
import Checkout from "./screens/Checkout";

const developerMode = true;

export default function App() {
  let apiURL = "https://cloud.grillchef.app";
  if (developerMode) {
    apiURL = "https://www.grillchef.servist.de";
  }

  /* Auth Token */
  const [authToken, setAuthToken] = useState("");

  /* userdata */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  /* orderdata */
  const [cart, setCart] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  /* handle Page */
  const [reload, setReload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const isMobile = useScreenType();
  const [scrollPosition, setScrollPosition] = useState(0);

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmentora");
      setAuthToken();
      setCompanyData([]);
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmentora");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtmentora")) {
    getData();
  }

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };
    const res = await fetch(`${apiURL}/api/authenticate`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmentora", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch(`${apiURL}/api/signup.php`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: username,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const {
    data: userData,
    loading: loadingUser,
    error: errorUser,
  } = useFetch(
    `${apiURL}/api/users/`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: companyData,
    loading: loadingCompanyData,
    error: errorCompanyData,
    setData: setCompanyData,
  } = useFetch(
    `${apiURL}/api/companies/?id=` + userData.companyID,
    authToken,
    "GET",
    null,
    [userData, authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: products,
    loading: loadingProducts,
    error: errorProducts,
    setData: setProducts,
  } = useFetch(
    `${apiURL}/api/products/index.php`,
    authToken,
    "GET",
    null,
    [authToken, reload] // Additional dependencies, if needed
  );

  /*
  TODOS:
  1. Stripe Zahlung einbinden mit CSS
  2. Datenbank Bestellung aufnehmen
  3. Bestellbestätigungsseite
  4. Email zur Bestellbestätigung
  5. Bestell-Daten ins CRM
  */

  return (
    <div className="app">
      <LanguageProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  scrollPosition={scrollPosition}
                  setScrollPosition={setScrollPosition}
                />
              }
            />
            <Route
              path="/product/:id"
              element={
                <Product
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                />
              }
            />
            <Route
              path="/cart/*"
              element={
                <Cart
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                />
              }
            />
            <Route
              path="/checkout/*"
              element={
                <Checkout
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  products={products}
                  cart={cart}
                  setCart={setCart}
                />
              }
            />
          </Routes>
          <></>
        </Router>
      </LanguageProvider>
    </div>
  );
}
